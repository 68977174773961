import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`Getting started with Nucleus is very easy.`}</p>
    <p>{`By following this short guide you will have the basic functionalities (analytics and bug reports) working in 5 minutes.`}</p>
    <h2 {...{
      "id": "create-an-app"
    }}>{`Create an app`}</h2>
    <p>{`Start by `}<a parentName="p" {...{
        "href": "/signup"
      }}>{`signing up`}</a>{` on the platform.`}</p>
    <p>{`Then, create your first app. Name it as you want, you can change it later.`}</p>
    <p>{`If you already have a version or an icon, you can edit the app to set those.`}</p>
    <h2 {...{
      "id": "integrate"
    }}>{`Integrate`}</h2>
    <p>{`Choose the appropriate module for your application, or use the API to report data if no module is available for your usecase.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/nodejs"
        }}>{`Electron / Node.js / JavaScript module`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/python"
        }}>{`Python module`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/swift"
        }}>{`Swift (MacOS) module`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/tracking-api"
        }}>{`Tracking API reference`}</a></li>
    </ul>
    <p>{`Be sure to add the correct `}<inlineCode parentName="p">{`<App Id>`}</inlineCode>{` when starting the module.`}</p>
    <p>{`That's it :)`}</p>
    <p>{`Open your app, and you should be able to see one user in your Nucleus dashboard.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      